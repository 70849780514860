import "./App.css";
import "./global/primary.css";
import "./global/grid.css";
import BookDemoSidebar from "./components/Book Demo Sidebar/BookDemoSidebar";
import Forms from "./components/Forms/Forms";
import { useState } from "react";

function App() {
  const [step, setStep] = useState(1);
  return (
    <div
      className="body-wrapper"
      style={{ height: step === 2 ? "96vh" : "100%" }}
    >
      <BookDemoSidebar step={step} />
      <Forms step={step} setStep={setStep} />
    </div>
  );
}

export default App;
