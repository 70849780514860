import React from "react";
import dateIcon from "../../assets/date-1.svg";
import timeIcon from "../../assets/time-1.svg";
export default function ScheduledInfo({
  dateSelected,
  timeSelected,
  timeZoneSelected,
  children,
}) {
  const formatDate = (date) => {
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
    };

    const getOrdinalSuffix = (day) => {
      const j = day % 10;
      const k = Math.floor(day / 10);
      if (k === 1) return "th"; // 11, 12, 13 are 'th'
      return j === 1 ? "st" : j === 2 ? "nd" : j === 3 ? "rd" : "th";
    };

    const formattedDate = date.toLocaleDateString("en-GB", options);

    const day = date.getDate();

    return `${formattedDate.replace(day, `, ${day}${getOrdinalSuffix(day)}`)}`;
  };
  return (
    <div className="meeting-details">
      {children}
      <h3>Schedule Demo for</h3>
      <div className="scheduled-date-time">
        <div className="scheduled-date">
          <div>
            <img src={dateIcon} alt="date" />
            {/* <span>Date</span> */}
          </div>
          <p>{formatDate(dateSelected)}</p>
        </div>

        <div className="scheduled-time">
          <div>
            <img src={timeIcon} alt="time" />
            {/* <span>Time</span> */}
          </div>
          <p>
            {timeSelected} {""} {`${timeZoneSelected}`}
          </p>
        </div>
      </div>
    </div>
  );
}
