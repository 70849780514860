// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.body-wrapper {
  background: #ffffff;
  border: 1px solid #b9daff;
  border-radius: 42px;
  margin: 2rem 3rem;
  padding: 2rem;
  position: relative;
  display: flex;
  gap: 12rem;
  height: 100%;
}
.company-logo {
  width: fit-content;
  position: absolute;
  top: 2rem;
  right: 2rem;
}
.error-message {
  color: red;
  font-size: 0.9em;
  position: absolute;
  bottom: -25px;
}
@media (max-width: 1440px) {
  .body-wrapper {
    gap: 6rem;
    bottom: -16px;
  }
}
@media (max-width: 1150px) {
  .body-wrapper {
    gap: 3rem;
  }
}
@media (max-width: 1024px) {
  .error-message {
    bottom: -19px;
    font-size: 1.3rem;
  }
}
@media (max-width: 768px) {
  .body-wrapper {
    gap: 0;
    flex-direction: column;
    height: 100% !important;
  }
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,yBAAyB;EACzB,mBAAmB;EACnB,iBAAiB;EACjB,aAAa;EACb,kBAAkB;EAClB,aAAa;EACb,UAAU;EACV,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,kBAAkB;EAClB,SAAS;EACT,WAAW;AACb;AACA;EACE,UAAU;EACV,gBAAgB;EAChB,kBAAkB;EAClB,aAAa;AACf;AACA;EACE;IACE,SAAS;IACT,aAAa;EACf;AACF;AACA;EACE;IACE,SAAS;EACX;AACF;AACA;EACE;IACE,aAAa;IACb,iBAAiB;EACnB;AACF;AACA;EACE;IACE,MAAM;IACN,sBAAsB;IACtB,uBAAuB;EACzB;AACF","sourcesContent":[".body-wrapper {\n  background: #ffffff;\n  border: 1px solid #b9daff;\n  border-radius: 42px;\n  margin: 2rem 3rem;\n  padding: 2rem;\n  position: relative;\n  display: flex;\n  gap: 12rem;\n  height: 100%;\n}\n.company-logo {\n  width: fit-content;\n  position: absolute;\n  top: 2rem;\n  right: 2rem;\n}\n.error-message {\n  color: red;\n  font-size: 0.9em;\n  position: absolute;\n  bottom: -25px;\n}\n@media (max-width: 1440px) {\n  .body-wrapper {\n    gap: 6rem;\n    bottom: -16px;\n  }\n}\n@media (max-width: 1150px) {\n  .body-wrapper {\n    gap: 3rem;\n  }\n}\n@media (max-width: 1024px) {\n  .error-message {\n    bottom: -19px;\n    font-size: 1.3rem;\n  }\n}\n@media (max-width: 768px) {\n  .body-wrapper {\n    gap: 0;\n    flex-direction: column;\n    height: 100% !important;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
