import React from "react";
import "./Steps.css";
export default function Steps({ step }) {
  return (
    <div className="steps-wrapper">
      <div className="step">
        <div>
          <p>1</p>
        </div>
        <div className="step-info">
          <h2>Your Information</h2>
          <p>Step 1</p>
        </div>
      </div>
      <div className="progress-bar-wrapper">
        <div className={`step-progress-bar ${step > 1 ? "active" : ""}`}></div>
      </div>
      <div className="step">
        <div className={`${step > 1 ? "" : "inactive"}`}>
          <p>2</p>
        </div>
        <div className="step-info">
          <h2>Personalization</h2>
          <p>Step 2</p>
        </div>
      </div>
      <div className="progress-bar-wrapper">
        <div className={`step-progress-bar ${step > 2 ? "active" : ""}`}></div>
      </div>
      <div className="step">
        <div className={`${step > 2 ? "" : "inactive"}`}>
          <p>3</p>
        </div>
        <div className="step-info">
          <h2>Schedule Demo</h2>
          <p>Step 3</p>
        </div>
      </div>
    </div>
  );
}
