import React from "react";
import submittedIcon from "../../assets/submittedIcon.svg";
import dateIcon from "../../assets/date-1.svg";
import timeIcon from "../../assets/time-1.svg";
export default function SubmittedBox({
  setStep,
  dateSelected,
  timeSelected,
  timeZoneSelected,
}) {
  const formatDate = (date) => {
    const options = {
      weekday: "long",
      month: "long",
      day: "numeric",
    };

    const getOrdinalSuffix = (day) => {
      const j = day % 10;
      const k = Math.floor(day / 10);
      if (k === 1) return "th"; // 11, 12, 13 are 'th'
      return j === 1 ? "st" : j === 2 ? "nd" : j === 3 ? "rd" : "th";
    };

    const formattedDate = date.toLocaleDateString("en-GB", options);

    const day = date.getDate();

    return `${formattedDate.replace(day, `, ${day}${getOrdinalSuffix(day)}`)}`;
  };
  return (
    <div className="submitted-box-overlay">
      <div className="submitted-box">
        <div className="scheduled-meeting-details">
          <img src={submittedIcon} alt="submitted-icon" />
          <div className="meeting-date-time">
            <div className="meeting-date">
              <div>
                <img src={dateIcon} alt="date" />
              </div>
              <p>{formatDate(dateSelected)}</p>
            </div>

            <div className="meeting-time">
              <div>
                <img src={timeIcon} alt="time" />
              </div>
              <p>
                {timeSelected} {""} {`${timeZoneSelected}`}
              </p>
            </div>
          </div>
        </div>

        <div className="submitted-message">
          <h2>AI Teammate will Shortly Connect and Schedule the Demo </h2>
          <button
            className="done-button"
            onClick={() => (window.location.href = "https://www.akira.ai/")}
          >
            <span>Done</span>
          </button>
        </div>
      </div>
    </div>
  );
}
