import "./FormComponents.css";

export const FormButtons = ({ children }) => {
  return <div className="buttons-wrapper">{children}</div>;
};

export const CancelButton = ({ name, onClick }) => {
  return (
    <button className="cancel-button" onClick={onClick}>
      <span>{name}</span>
    </button>
  );
};

export const SubmitButton = ({ name, boolVar = true, onClick }) => {
  return (
    <button
      className={`submit-button ${!boolVar ? "disabled" : ""}`}
      onClick={onClick}
      style={{
        cursor: boolVar ? "pointer" : "not-allowed",
        background: boolVar ? "#006bff" : "#3c8eff",
        border: boolVar ? "1px solid #006bff" : "1px solid #3c8eff",
      }}
    >
      <span>{name}</span>
    </button>
  );
};
