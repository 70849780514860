import React, { useEffect, useRef, useState } from "react";
import "./FormComponents.css";
import { questionsList } from "./OptionsList";
import InputBox from "./InputBox";
import { CancelButton, FormButtons, SubmitButton } from "./FormComponents";
export default function Questions({
  setStep,
  currentQuestionIndex,
  setCurrentQuestionIndex,
}) {
  const questions = questionsList;
  const [qaList, setQaList] = useState([]);
  const [ansIndexes, setansIndexes] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(null);
  const [showInput, setShowInput] = useState(false);
  const [inputIndex, setInputIndex] = useState(null);
  const [inputData, setInputData] = useState([]);
  const questionRefs = useRef([]);

  useEffect(() => {
    let savedQaList = JSON.parse(sessionStorage.getItem("qaList")) || [];
    let savedInputData = JSON.parse(sessionStorage.getItem("inputData")) || [];
    let savedAnsIndexes =
      JSON.parse(sessionStorage.getItem("ansIndexes")) || [];

    if (savedQaList.length > 0 && savedAnsIndexes.length > 0) {
      setansIndexes(savedAnsIndexes);
      setCurrentQuestionIndex(questions.length);
      setQaList(savedQaList);
      setInputData(savedInputData);
    }
    sessionStorage.removeItem("qaList");
    sessionStorage.removeItem("ansIndexes");
    savedQaList = null;
    savedAnsIndexes = null;
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (questionRefs.current[currentQuestionIndex]) {
      questionRefs.current[currentQuestionIndex].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [currentQuestionIndex]);

  const handleAnswerClick = (e, aIndex, qIndex) => {
    e.preventDefault();
    if (showInput && qIndex !== inputIndex) return;

    const question = questions[qIndex].question;
    const answer = e.target.innerText;

    if (aIndex < questions[qIndex].answers.length - 1) {
      setShowInput(false);
      setInputData((prev) => ({
        ...prev,
        [qIndex]: null,
      }));
    }
    if (aIndex === questions[qIndex].answers.length - 1) {
      setQuestionIndex(qIndex);
      if (ansIndexes[qIndex]) {
        setansIndexes((prev) =>
          prev.map((item, i) => (i === qIndex ? { ...item, aIndex } : item))
        );
      } else {
        setansIndexes([...ansIndexes, { qIndex, aIndex }]);
      }
      setShowInput(true);
      setInputIndex(qIndex);
      setQuestionIndex(qIndex);
    } else if (
      currentQuestionIndex < questions.length &&
      qIndex >= currentQuestionIndex
    ) {
      if (qaList[qIndex]) {
        setQaList((prev) =>
          prev.map((item, i) => (i === qIndex ? { ...item, answer } : item))
        );
      } else {
        setQaList([...qaList, { question, answer }]);
      }

      if (ansIndexes[qIndex]) {
        setansIndexes((prev) =>
          prev.map((item, i) => (i === qIndex ? { ...item, aIndex } : item))
        );
      } else {
        setansIndexes([...ansIndexes, { qIndex, aIndex }]);
      }
      setCurrentQuestionIndex(currentQuestionIndex + 1);
      setShowInput(false);
    } else {
      setQaList((prev) =>
        prev.map((item, i) => (i === qIndex ? { ...item, answer } : item))
      );
      setansIndexes((prev) =>
        prev.map((item, i) => (i === qIndex ? { ...item, aIndex } : item))
      );
    }
  };

  const handleInputAnswer = (inputData) => {
    const question = questions[questionIndex].question;
    const answer = inputData;

    const updatedQaList = [...qaList];
    if (updatedQaList[questionIndex]) {
      updatedQaList[questionIndex] = { question, answer };
    } else {
      updatedQaList.push({ question, answer });
    }
    setQaList(updatedQaList);

    const updatedAnsIndexes = [...ansIndexes];
    setansIndexes(updatedAnsIndexes);
    setInputData((prev) => ({
      ...prev,
      [questionIndex]: inputData,
    }));

    if (questionIndex >= currentQuestionIndex) {
      setShowInput(false);
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      setShowInput(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    sessionStorage.setItem("qaList", JSON.stringify(qaList));
    sessionStorage.setItem("ansIndexes", JSON.stringify(ansIndexes));
    sessionStorage.setItem("inputData", JSON.stringify(inputData));

    if (currentQuestionIndex === questions.length) {
      if (showInput) return;
      setStep(3);
    }
  };
  const handleBackButton = () => {
    sessionStorage.setItem("qaList", JSON.stringify(qaList));
    sessionStorage.setItem("ansIndexes", JSON.stringify(ansIndexes));
    sessionStorage.setItem("inputData", JSON.stringify(inputData));
    sessionStorage.setItem("backPressed", JSON.stringify(true));
    setStep(1);
  };
  return (
    <>
      {questions.map((question, qIndex) => (
        <div
          key={qIndex}
          className={`questions ${
            qIndex <= currentQuestionIndex ? "active-question" : ""
          }`}
          ref={(el) => (questionRefs.current[qIndex] = el)}
        >
          <h3>
            {qIndex + 1}. {questions[qIndex].question}
          </h3>
          <div className="answers">
            {question.answers.map((answer, aIndex) => (
              <button
                key={aIndex}
                className={`answer-button ${
                  ansIndexes[qIndex]?.aIndex === aIndex
                    ? "ans-selected"
                    : showInput === true && qIndex !== inputIndex
                    ? "disabled"
                    : ""
                }`}
                onClick={(e) => handleAnswerClick(e, aIndex, qIndex)}
              >
                <p>{answer}</p>
              </button>
            ))}
            {inputData[qIndex] && (
              <button
                key={`input-${qIndex}`}
                className="answer-button ans-selected new-answer"
              >
                <p>{inputData[qIndex]}</p>
              </button>
            )}
          </div>
        </div>
      ))}
      {showInput && (
        <InputBox handleInputAnswer={(data) => handleInputAnswer(data)} />
      )}
      <FormButtons>
        <CancelButton name="Back" onClick={handleBackButton} />
        <SubmitButton
          name="Next"
          boolVar={currentQuestionIndex === questions.length && !showInput}
          onClick={handleSubmit}
        />
      </FormButtons>
    </>
  );
}
