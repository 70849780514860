export const IndustryList = [
  { value: "Aerospace", label: "Aerospace" },
  { value: "Agriculture", label: "Agriculture" },
  { value: "Automotive", label: "Automotive" },
  {
    value: "Banking and Finance Sector",
    label: "Banking and Finance Sector",
  },
  { value: "Consumer Goods", label: "Consumer Goods" },
  { value: "Consumer Technology", label: "Consumer Technology" },
  { value: "Education", label: "Education" },
  { value: "Enterprise Technology", label: "Enterprise Technology" },
  { value: "Financial Services", label: "Financial Services" },
  { value: "Gaming", label: "Gaming" },
  { value: "Government", label: "Government" },
  { value: "Healthcare", label: "Healthcare" },
  { value: "Hospitality", label: "Hospitality" },
  { value: "Insurance", label: "Insurance" },
  { value: "Life Sciences", label: "Life Sciences" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Marketing & Advertising", label: "Marketing & Advertising" },
  { value: "Media", label: "Media" },
  { value: "Mining", label: "Mining" },
  { value: "Non-Profit Organization", label: "Non-Profit Organization" },
  { value: "Oil and Gas", label: "Oil and Gas" },
  { value: "Power & Utilities", label: "Power & Utilities" },
  { value: "Professional Services", label: "Professional Services" },
  {
    value: "Real Estate and Construction",
    label: "Real Estate and Construction",
  },
  { value: "Retail", label: "Retail" },
  { value: "Telecommunication", label: "Telecommunication" },
  {
    value: "Transportation and Logistics",
    label: "Transportation and Logistics",
  },
  { value: "Travel", label: "Travel" },
  {
    value: "Wholesale and Distribution",
    label: "Wholesale and Distribution",
  },
  { value: "Other", label: "Other" },
];

export const jobTitles = [
  { value: "Business Owner/Leader", label: "Business Owner/Leader" },
  {
    value: "C-Suite (CEO, CTO, COO, CFO, CIO)",
    label: "C-Suite (CEO, CTO, COO, CFO, CIO)",
  },
  { value: "Vice-President", label: "Vice-President" },
  { value: "Director", label: "Director" },
  { value: "Program Manager", label: "Program Manager" },
  { value: "Head of Function", label: "Head of Function" },
  { value: "Subject Matter Expert", label: "Subject Matter Expert" },
  { value: "Senior Consultant/Engineer", label: "Senior Consultant/Engineer" },
  { value: "Other", label: "Other" },
];

export const countries = [
  { value: "Afghanistan", label: "Afghanistan" },
  { value: "Albania", label: "Albania" },
  { value: "Algeria", label: "Algeria" },
  { value: "Andorra", label: "Andorra" },
  { value: "Angola", label: "Angola" },
  { value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
  { value: "Argentina", label: "Argentina" },
  { value: "Armenia", label: "Armenia" },
  { value: "Australia", label: "Australia" },
  { value: "Austria", label: "Austria" },
  { value: "Azerbaijan", label: "Azerbaijan" },
  { value: "Bahamas", label: "Bahamas" },
  { value: "Bahrain", label: "Bahrain" },
  { value: "Bangladesh", label: "Bangladesh" },
  { value: "Barbados", label: "Barbados" },
  { value: "Belarus", label: "Belarus" },
  { value: "Belgium", label: "Belgium" },
  { value: "Belize", label: "Belize" },
  { value: "Benin", label: "Benin" },
  { value: "Bhutan", label: "Bhutan" },
  { value: "Bolivia", label: "Bolivia" },
  { value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
  { value: "Botswana", label: "Botswana" },
  { value: "Brazil", label: "Brazil" },
  { value: "Brunei", label: "Brunei" },
  { value: "Bulgaria", label: "Bulgaria" },
  { value: "Burkina Faso", label: "Burkina Faso" },
  { value: "Burundi", label: "Burundi" },
  { value: "Cabo Verde", label: "Cabo Verde" },
  { value: "Cambodia", label: "Cambodia" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Central African Republic", label: "Central African Republic" },
  { value: "Chad", label: "Chad" },
  { value: "Chile", label: "Chile" },
  { value: "China", label: "China" },
  { value: "Colombia", label: "Colombia" },
  { value: "Comoros", label: "Comoros" },
  { value: "Congo", label: "Congo" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Cuba", label: "Cuba" },
  { value: "Cyprus", label: "Cyprus" },
  { value: "Czech Republic", label: "Czech Republic" },
  { value: "Denmark", label: "Denmark" },
  { value: "Djibouti", label: "Djibouti" },
  { value: "Dominica", label: "Dominica" },
  { value: "Dominican Republic", label: "Dominican Republic" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "Egypt", label: "Egypt" },
  { value: "El Salvador", label: "El Salvador" },
  { value: "Equatorial Guinea", label: "Equatorial Guinea" },
  { value: "Eritrea", label: "Eritrea" },
  { value: "Estonia", label: "Estonia" },
  { value: "Eswatini", label: "Eswatini" },
  { value: "Ethiopia", label: "Ethiopia" },
  { value: "Fiji", label: "Fiji" },
  { value: "Finland", label: "Finland" },
  { value: "France", label: "France" },
  { value: "Gabon", label: "Gabon" },
  { value: "Gambia", label: "Gambia" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Greece", label: "Greece" },
  { value: "Grenada", label: "Grenada" },
  { value: "Guatemala", label: "Guatemala" },
  { value: "Guinea", label: "Guinea" },
  { value: "Guinea-Bissau", label: "Guinea-Bissau" },
  { value: "Guyana", label: "Guyana" },
  { value: "Haiti", label: "Haiti" },
  { value: "Honduras", label: "Honduras" },
  { value: "Hungary", label: "Hungary" },
  { value: "Iceland", label: "Iceland" },
  { value: "India", label: "India" },
  { value: "Indonesia", label: "Indonesia" },
  { value: "Iran", label: "Iran" },
  { value: "Iraq", label: "Iraq" },
  { value: "Ireland", label: "Ireland" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Jamaica", label: "Jamaica" },
  { value: "Japan", label: "Japan" },
  { value: "Jordan", label: "Jordan" },
  { value: "Kazakhstan", label: "Kazakhstan" },
  { value: "Kenya", label: "Kenya" },
  { value: "Kiribati", label: "Kiribati" },
  { value: "North Korea", label: "North Korea" },
  { value: "South Korea", label: "South Korea" },
  { value: "Kosovo", label: "Kosovo" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Kyrgyzstan", label: "Kyrgyzstan" },
  { value: "Laos", label: "Laos" },
  { value: "Latvia", label: "Latvia" },
  { value: "Lebanon", label: "Lebanon" },
  { value: "Lesotho", label: "Lesotho" },
  { value: "Liberia", label: "Liberia" },
  { value: "Libya", label: "Libya" },
  { value: "Liechtenstein", label: "Liechtenstein" },
  { value: "Lithuania", label: "Lithuania" },
  { value: "Luxembourg", label: "Luxembourg" },
  { value: "Madagascar", label: "Madagascar" },
  { value: "Malawi", label: "Malawi" },
  { value: "Malaysia", label: "Malaysia" },
  { value: "Maldives", label: "Maldives" },
  { value: "Mali", label: "Mali" },
  { value: "Malta", label: "Malta" },
  { value: "Marshall Islands", label: "Marshall Islands" },
  { value: "Mauritania", label: "Mauritania" },
  { value: "Mauritius", label: "Mauritius" },
  { value: "Mexico", label: "Mexico" },
  { value: "Micronesia", label: "Micronesia" },
  { value: "Moldova", label: "Moldova" },
  { value: "Monaco", label: "Monaco" },
  { value: "Mongolia", label: "Mongolia" },
  { value: "Montenegro", label: "Montenegro" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Myanmar", label: "Myanmar" },
  { value: "Namibia", label: "Namibia" },
  { value: "Nauru", label: "Nauru" },
  { value: "Nepal", label: "Nepal" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "New Zealand", label: "New Zealand" },
  { value: "Nicaragua", label: "Nicaragua" },
  { value: "Niger", label: "Niger" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "North Macedonia", label: "North Macedonia" },
  { value: "Norway", label: "Norway" },
  { value: "Oman", label: "Oman" },
  { value: "Pakistan", label: "Pakistan" },
  { value: "Palau", label: "Palau" },
  { value: "Panama", label: "Panama" },
  { value: "Papua New Guinea", label: "Papua New Guinea" },
  { value: "Paraguay", label: "Paraguay" },
  { value: "Peru", label: "Peru" },
  { value: "Philippines", label: "Philippines" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Qatar", label: "Qatar" },
  { value: "Romania", label: "Romania" },
  { value: "Russia", label: "Russia" },
  { value: "Rwanda", label: "Rwanda" },
  { value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
  { value: "Saint Lucia", label: "Saint Lucia" },
  {
    value: "Saint Vincent and the Grenadines",
    label: "Saint Vincent and the Grenadines",
  },
  { value: "Samoa", label: "Samoa" },
  { value: "San Marino", label: "San Marino" },
  { value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Seychelles", label: "Seychelles" },
  { value: "Sierra Leone", label: "Sierra Leone" },
  { value: "Singapore", label: "Singapore" },
  { value: "Slovakia", label: "Slovakia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Solomon Islands", label: "Solomon Islands" },
  { value: "Somalia", label: "Somalia" },
  { value: "South Africa", label: "South Africa" },
  { value: "South Sudan", label: "South Sudan" },
  { value: "Spain", label: "Spain" },
  { value: "Sri Lanka", label: "Sri Lanka" },
  { value: "Sudan", label: "Sudan" },
  { value: "Suriname", label: "Suriname" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Syria", label: "Syria" },
  { value: "Taiwan", label: "Taiwan" },
  { value: "Tajikistan", label: "Tajikistan" },
  { value: "Tanzania", label: "Tanzania" },
  { value: "Thailand", label: "Thailand" },
  { value: "Timor-Leste", label: "Timor-Leste" },
  { value: "Togo", label: "Togo" },
  { value: "Tonga", label: "Tonga" },
  { value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
  { value: "Tunisia", label: "Tunisia" },
  { value: "Turkey", label: "Turkey" },
  { value: "Turkmenistan", label: "Turkmenistan" },
  { value: "Tuvalu", label: "Tuvalu" },
  { value: "Uganda", label: "Uganda" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "United Arab Emirates", label: "United Arab Emirates" },
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "United States", label: "United States" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Uzbekistan", label: "Uzbekistan" },
  { value: "Vanuatu", label: "Vanuatu" },
  { value: "Vatican City", label: "Vatican City" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Vietnam", label: "Vietnam" },
  { value: "Yemen", label: "Yemen" },
  { value: "Zambia", label: "Zambia" },
  { value: "Zimbabwe", label: "Zimbabwe" },
];

export const questionsList = [
  {
    question: "Which segment does your company belong to?",
    answers: [
      "Startup",
      "Scale Startup",
      "SME",
      "Mid Enterprises",
      "Large Enterprises",
      "Government/Public Sector",
      "Non-profit Organizations",
      "Other: (Please Specify)",
    ],
  },
  {
    question: "Select your primary focus area:",
    answers: [
      "Information Technology (IT) Service Management",
      "IT Operation Management",
      "Security Operations",
      "Finance Operations",
      "HR Service Delivery",
      "Sourcing and Procurement",
      "Legal and Compliance",
      "IT Asset Management",
      "Customer Service/Support",
      "Logistics and Warehouse Management",
      "Delivery/Transport Management",
      "Clinical Operations",
      "Sales and Marketing",
      "Research and Development",
      "Inventory Management",
      "Risk Management and Governance",
      "Customer Relationship Management (CRM)",
      "Manufacturing Operations",
      "Energy Management",
      "Telecom Operations",
      "Other: (Please Specify)",
    ],
  },
  {
    question: "What stage is your AI use case currently in?",
    answers: [
      "Conceptualized: Use case defined, PoC pending",
      "Proof of Concept (PoC) Completed",
      "In Production with Challenges",
      "Not Yet Defined",
      "Other: (Please Specify)",
    ],
  },
  {
    question: "What are the primary challenges in adopting AI?:",
    answers: [
      "Data Quality Issues",
      "Data Privacy and Compliance",
      "Aligning AI with Business Goals",
      "Unclear ROI from PoCs",
      "Integration with Existing ERP Systems",
      "Scalability Challenges",
      "Moving PoCs to Production",
      "Infrastructure Limitations",
      "High Implementation Costs",
      "None of the Above",
      "Other: (Please Specify)",
    ],
  },
  {
    question:
      "What kind of infrastructure does your organization currently use for deploying AI solutions?",
    answers: [
      "AWS (Amazon Web Services)",
      "Microsoft Azure",
      "Google Cloud Platform (GCP)",
      "IBM Cloud",
      "Oracle Cloud",
      "On-premises",
      "Hybrid (Combination of cloud and on-prem) ",
      "Other: (Please Specify)",
    ],
  },
  {
    question: "Preferred approaches to AI agent adoption:",
    answers: [
      "Assisted Intelligence Agents",
      "Collaborative Intelligence Agents",
      "Autonomous Intelligence Agents",
      "Agentic Workflows",
      "Other: (Please Specify)",
    ],
  },

  {
    question: "What metrics are most important for success?:",
    answers: [
      "Standardization and Simplification",
      "Operational Efficiency",
      "Cost Reduction",
      "Customer Satisfaction",
      "Revenue Growth",
      "Time to Market",
      "Employee Productivity",
      "Innovation Rate",
      "Compliance and Risk Management",
      "Scalability and Flexibility",
      "Data Accuracy and Integrity",
      "Return on Investment (ROI)",
      "Other: (Please Specify)",
    ],
  },
];

export const timeSlots = [
  [
    "9:00 AM - 9:15 AM",
    "9:15 AM - 9:30 AM",
    "9:30 AM - 9:45 AM",
    "9:45 AM - 10:00 AM",
    "10:00 AM - 10:15 AM",
    "10:15 AM - 10:30 AM",
    "10:30 AM - 10:45 AM",
    "10:45 AM - 11:00 AM",
    "11:00 AM - 11:15 AM",
    "11:15 AM - 11:30 AM",
    "11:30 AM - 11:45 AM",
    "11:45 AM - 12:00 PM",
    "12:00 PM - 12:15 PM",
    "12:15 PM - 12:30 PM",
    "12:30 PM - 12:45 PM",
    "12:45 PM - 1:00 PM",
    "1:00 PM - 1:15 PM",
    "1:15 PM - 1:30 PM",
    "1:30 PM - 1:45 PM",
    "1:45 PM - 2:00 PM",
    "2:00 PM - 2:15 PM",
    "2:15 PM - 2:30 PM",
    "2:30 PM - 2:45 PM",
    "2:45 PM - 3:00 PM",
    "3:00 PM - 3:15 PM",
    "3:15 PM - 3:30 PM",
    "3:30 PM - 3:45 PM",
    "3:45 PM - 4:00 PM",
    "4:00 PM - 4:15 PM",
    "4:15 PM - 4:30 PM",
    "4:30 PM - 4:45 PM",
    "4:45 PM - 5:00 PM",
    "5:00 PM - 5:15 PM",
    "5:15 PM - 5:30 PM",
    "5:30 PM - 5:45 PM",
    "5:45 PM - 6:00 PM",
    "6:00 PM - 6:15 PM",
    "6:15 PM - 6:30 PM",
    "6:30 PM - 6:45 PM",
    "6:45 PM - 7:00 PM",
    "7:00 PM - 7:15 PM",
    "7:15 PM - 7:30 PM",
    "7:30 PM - 7:45 PM",
    "7:45 PM - 8:00 PM",
    "8:00 PM - 8:15 PM",
    "8:15 PM - 8:30 PM",
    "8:30 PM - 8:45 PM",
    "8:45 PM - 9:00 PM",
    "9:00 PM - 9:15 PM",
    "9:15 PM - 9:30 PM",
    "9:30 PM - 9:45 PM",
    "9:45 PM - 10:00 PM",
  ],

  [
    "9:00 AM - 9:30 AM",
    "9:30 AM - 10:00 AM",
    "10:00 AM - 10:30 AM",
    "10:30 AM - 11:00 AM",
    "11:00 AM - 11:30 AM",
    "11:30 AM - 12:00 PM",
    "12:00 PM - 12:30 PM",
    "12:30 PM - 1:00 PM",
    "1:00 PM - 1:30 PM",
    "1:30 PM - 2:00 PM",
    "2:00 PM - 2:30 PM",
    "2:30 PM - 3:00 PM",
    "3:00 PM - 3:30 PM",
    "3:30 PM - 4:00 PM",
    "4:00 PM - 4:30 PM",
    "4:30 PM - 5:00 PM",
    "5:00 PM - 5:30 PM",
    "5:30 PM - 6:00 PM",
    "6:00 PM - 6:30 PM",
    "6:30 PM - 7:00 PM",
    "7:00 PM - 7:30 PM",
    "7:30 PM - 8:00 PM",
    "8:00 PM - 8:30 PM",
    "8:30 PM - 9:00 PM",
    "9:00 PM - 9:30 PM",
    "9:30 PM - 10:00 PM",
  ],
];
